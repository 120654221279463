// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
    useGetArticleBuilderQuery,
    useDeleteArticleMutation,
    useDuplicateArticleMutation,
} from "@api/apiV6";
import { useSelector } from "react-redux";

import { Div, Image } from "@components/Generics.react";
import "@styles/navbars.scss";
import { text_truncate } from "@utilities/helpers";

import { Loader, Confirm, Icon } from "semantic-ui-react";
import ActionCard from "@components/ActionCard.react";
import PreviewLayout from "@layouts/PreviewLayout.react";
import { deckFormats } from "@schemas/deckFormats";

export default function ArticleView(props) {
    const { id, setTarget } = props;
    const user = useSelector((state) => state.auth.user);
    const { data: article, isSuccess, refetch } = useGetArticleBuilderQuery(id);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const articleType =
        _.find(deckFormats, {
            readerType: article?.category,
        }) || deckFormats[article?.category];
    const [deleteArticle] = useDeleteArticleMutation();
    const [duplicateArticle] = useDuplicateArticleMutation();
    const { t } = useTranslation("common");
    const isOwner = isSuccess && user.team === article.owner?.team;

    function doDelete() {
        const storedRecents = window.localStorage.getItem("libraryRecents");
        let libraryRecents = [];
        if (storedRecents) {
            libraryRecents = JSON.parse(storedRecents);
        }
        _.remove(libraryRecents, {
            key: article._id,
        });
        window.localStorage.setItem(
            "libraryRecents",
            JSON.stringify(libraryRecents)
        );
        deleteArticle(article._id);
    }
    async function doCopy() {
        const newArticle = await duplicateArticle(article._id);
        if (newArticle) {
            const storedRecents = window.localStorage.getItem("libraryRecents");
            let libraryRecents = [];
            if (storedRecents) {
                libraryRecents = JSON.parse(storedRecents);
            }
            libraryRecents = [
                {
                    key: newArticle.data._id,
                    section: "articles",
                    title: newArticle.data.name,
                    type: "child",
                },
                ...libraryRecents,
            ];
            window.localStorage.setItem(
                "libraryRecents",
                JSON.stringify(libraryRecents)
            );
            toast("Article Duplicated");
            setTarget({
                key: newArticle.data._id,
                section: "articles",
                title: newArticle.data.name,
                type: "child",
            });
        }
    }

    const actionMap = {
        setup:
            isOwner &&
            `/builder/articles/${articleType?.category}/${article?._id}/setup`,
        edit:
            isOwner &&
            article?.decks &&
            `/builder/editor/${
                deckFormats[articleType?.category]?.readerType
            }/${article?.decks[0]}/${article?.name}`,
        share:
            isOwner &&
            `/builder/articles/${articleType?.category}/${article?._id}/share`,
        analytics:
            isOwner &&
            `/builder/articles/${articleType?.category}/${article?._id}/analytics`,
    };

    // ========================= Render Function =================================
    return isSuccess && article ? (
        <Div
            fluid
            flex
            noWrap
            spaceAround
            flexStart
            fullht
            basepad
            noBottomPad
            noOverflow
        >
            <Div basepad txtCharcoal fullht autoOverflowY fluid>
                <Div flex spaceBetween gapped>
                    <Div
                        fullht
                        wd="15px"
                        ht="210px"
                        style={{ background: "#ff69b4" }}
                    />
                    <Div wd="calc(100% - 30px)">
                        {isOwner && (
                            <Div
                                float-right
                                big
                                clickable
                                style={{ marginTop: "15px" }}
                                wd="128px"
                            >
                                <Icon
                                    bordered
                                    color="blue"
                                    inverted
                                    circular
                                    name="copy"
                                    onClick={doCopy}
                                />
                                <Icon
                                    bordered
                                    color="olive"
                                    inverted
                                    circular
                                    name="refresh"
                                    onClick={() => {
                                        refetch();
                                        toast("Article Refreshed");
                                    }}
                                />
                                <Icon
                                    bordered
                                    color="red"
                                    inverted
                                    circular
                                    name="trash"
                                    onClick={() => setDeleteConfirm(true)}
                                />
                            </Div>
                        )}
                        <Div wd="calc(100% - 128px)">
                            <Div uppercase>
                                {t("components.edit")}{" "}
                                {articleType?.name ||
                                    article.category ||
                                    "Article"}
                                :
                            </Div>
                            <Div
                                huge
                                altText
                                uppercase
                                gutter
                                title={article.name}
                            >
                                {text_truncate(article.name, 30)}
                            </Div>
                        </Div>
                        <Image src={article.image} snubbed />{" "}
                    </Div>
                </Div>
                <Div clear maxWd="460px">
                    <br />
                    <Div basepad ash snubbed>
                        {_.keys(_.pickBy(actionMap)) > 1 ? (
                            _.map(_.keys(_.pickBy(actionMap)), (act, idx) => (
                                <ActionCard
                                    key={`${article._id}-action-${idx}`}
                                    icon={t(
                                        `builder.viewPanels.article.${act}.icon`
                                    )}
                                    header={t(
                                        `builder.viewPanels.article.${act}.header`
                                    )}
                                    content={t(
                                        `builder.viewPanels.article.${act}.content`
                                    )}
                                    link={actionMap[act]}
                                />
                            ))
                        ) : (
                            <>
                                {_.keys(_.pickBy(actionMap))?.length < 2 && (
                                    <Div danger basepad snubbed gutter>
                                        {t(
                                            `builder.viewPanels.article.uneditable`
                                        )}
                                    </Div>
                                )}
                                {_.map(
                                    _.keys(_.pickBy(actionMap)),
                                    (act, idx) => (
                                        <ActionCard
                                            key={`${article._id}-action-${idx}`}
                                            icon={t(
                                                `builder.viewPanels.article.${act}.icon`
                                            )}
                                            header={t(
                                                `builder.viewPanels.article.${act}.header`
                                            )}
                                            content={t(
                                                `builder.viewPanels.article.${act}.content`
                                            )}
                                            link={actionMap[act]}
                                        />
                                    )
                                )}
                            </>
                        )}
                    </Div>
                </Div>
            </Div>
            <PreviewLayout
                url={`content/${article.category}/${article.identifier}`}
            />
            <Confirm
                open={deleteConfirm}
                onCancel={() => setDeleteConfirm(false)}
                onConfirm={() => {
                    setTarget(false);
                    doDelete();
                    setDeleteConfirm(false);
                    toast("Article Deleted");
                }}
            />
        </Div>
    ) : (
        <Loader active inverted />
    );
}
