export const notificationConfig = {
    // ------------------------------------------------------------
    // => This is to be used to fetch notification structure based on 
    // notification type
    // ------------------------------------------------------------
        addUser: {
            type: "ADD_USER",
            title: "Welcome To VIL Learning App",
            description: "Welcome To VIL Learning App!!",
            link: "",
            route: "sendinblue",
            inAppNotification: true,
            emailNotification: true,
            pushNotification: false,
            smsNotification: false
        },
        enrolUser: {
            type: "USER_ENROLLMENT",
            title: "Course Enrollment",
            description: "$User enrolled you into new Course $courseName",
            link: "",
            route: "sendinblue",
            inAppNotification: true,
            emailNotification: true,
            pushNotification: false,
            smsNotification: false
        },
        broadcastMessage: {
            type: "BROADCAST_MESSAGE",
            title: "Broadcast Mesage",
            description: "",
            link: "",
            route: "sendinblue",
            inAppNotification: true,
            emailNotification: true,
            pushNotification: false,
            smsNotification: false
        }  
}
