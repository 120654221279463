import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { LayoutContext } from "@layouts/MasterLayout.react";
import { baseUrl } from "@api/apiV6";
import { Div, Icon } from "@components/Generics.react";
import { devMode } from "@utilities/helpers";
import "@styles/readers.scss";

export default function SCORMReader(props) {
    const { deck, recordAttempt } = props;
    const user = useSelector((state) => state.auth.user);
    const { fullScreen, setFullScreen } = useContext(LayoutContext);

    const [loading, setLoading] = useState(true);
    const [suspendData, setSuspendData] = useState("");
    const isPortrait = window.innerHeight > window.innerWidth;

    // Variable to detect if the device is iOS (iPad, iPhone, iPod)
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    let currentViewed = 0;
    let currentScore = 0;
    let pointsDelta = 0;

    useEffect(() => {
        // Event listener for receiving messages from other windows
        window.addEventListener("message", receiveMessage, false);

        // Retrieve suspended data from local storage
        const sData = localStorage.getItem(
            `suspend_data_${deck._id}_${user._id}`
        );
        setSuspendData(sData);
        setLoading(false);

        // Conditionally set full-screen mode, only if not on iOS
        if (setFullScreen && !isIOS) setFullScreen(true);

        return () => {
            // Cleanup event listener when component unmounts
            window.removeEventListener("message", receiveMessage, false);
            if(setFullScreen && !isIOS) setFullScreen(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // The session time "0000:00:29.86" it appears to be in the format HHHH:MM:SS.ss
    // This function converts it to seconds
    function sessionTimeToSeconds(sessionTime) {
        const parts = sessionTime.split(":");
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const seconds = parseFloat(parts[2]);

        return Math.round(hours * 3600 + minutes * 60 + seconds);
    }

    // Function to handle messages received from the SCORM iframe
    function receiveMessage(event) {
        if (
            event.origin === baseUrl ||
            event.origin + "/" === baseUrl ||
            event.origin + "/api/" === baseUrl ||
            event.origin + "/api" === baseUrl ||
            devMode
        ) {
            let messageData = isJson(event.data)
                ? JSON.parse(event.data)
                : {
                    suspend_data: "",
                    core: {
                        session_time: "",
                        interactions: { childArray: [] },
                        score: { raw: 0 },
                        lesson_status: "incomplete",
                    },
                };
            console.log(messageData);

            // Store suspended data in local storage
            window.localStorage.setItem(
                `suspend_data_${deck._id}_${user._id}`,
                messageData.suspend_data
            );

            // Calculate viewed percentage and score from message data
            let viewedPercentage =
                messageData.core &&
                    (messageData.core.lesson_status === "passed" ||
                        messageData.core.lesson_status === "completed")
                    ? 100
                    : messageData.success_status === "passed" ||
                        messageData.success_status === "completed"
                        ? 100
                        : messageData.completion_status === "passed" ||
                            messageData.completion_status === "completed"
                            ? 100
                            : 0;
            let score =
                messageData.core && messageData.core.score
                    ? parseInt(messageData.core.score.raw, 0)
                    : messageData.score
                        ? parseInt(messageData.score.raw, 0)
                        : null;

            // Convert session time to seconds
            let sessionTime =
                messageData.core && messageData.core.session_time
                    ? sessionTimeToSeconds(messageData.core.session_time)
                    : "NA";
            console.log("sessionTime: ", sessionTime);

            // Update current viewed percentage and score
            currentViewed = Math.max(currentViewed, viewedPercentage);
            currentScore = Math.max(currentScore, score || 0);

            // Calculate points delta for the current session
            pointsDelta =
                currentScore != null &&
                    deck.currentState != null &&
                    deck.points != null
                    ? Math.max(
                        (currentScore * deck.points) / 100 -
                        (deck.currentState.points || 0),
                        0
                    )
                    : 0;

            // Record the current attempt with updated information
            recordAttempt({
                viewedPercentage: currentViewed,
                completion: currentViewed === 100,
                pointsDelta: pointsDelta,
                score: currentScore,
                sessionTime: sessionTime,
            });
        }
    }
    // function toggleFullScreen() {
    //     setFullScreen({ fullScreen: !fullScreen });
    // }

    if (loading) {
        return <Div />;
    } else {
        // Prepare user data for SCORM iframe
        let userData = encodeURIComponent(
            JSON.stringify(
                (({ username, first_name, last_name }) => ({
                    v1: username,
                    v2: first_name,
                    v3: last_name,
                }))(user)
            )
        );

        // Construct the SCORM iframe link with user and deck information
        let link =
            baseUrl +
            (baseUrl.charAt(baseUrl.length - 1) === "/" ? "" : "/") +
            "scorm?auth=" +
            userData +
            "&path=" +
            deck.path +
            "&suspend_data='" +
            suspendData +
            "'";
        return (
            <Div fluid fullht>
                <Div
                    relative
                    fluid
                    ht={isPortrait ? "calc(100vh - 200px)" : "100%"}
                    className="fullscreen-target"
                >
                    {/* SCORM content is loaded in an iframe */}
                    <iframe
                        title="scormframe"
                        id="scormframe"
                        src={link}
                        name="course"
                        width="100%"
                        height="100%"
                    />
                    {/* Display full-screen button only if not on iOS and full screen is not active */}
                    {isPortrait &&
                        !fullScreen &&
                        !isIOS && (
                            <Div rimmed midnight className="gofull-btn">
                                <Icon
                                    name="expand"
                                    onClick={() => setFullScreen(true)}
                                />
                            </Div>
                        )}
                </Div>
                {/* Spacer div for portrait mode */}
                {isPortrait && <Div ht={"45px"} />}
                {/* Full-screen toggle button, hidden for iOS */}
                {!isIOS && (
                    <Div
                        rimmed
                        charcoal
                        large
                        snubbed
                        clickable
                        className="expand-icon"
                        onClick={() => setFullScreen(!fullScreen)}
                    >
                        <Icon name="expand" inline fitted />
                    </Div>
                )}
            </Div>
        );
    }
}

// Utility function to check if a string is valid JSON
function isJson(json) {
    try {
        JSON.parse(json);
        return true;
    } catch (e) {
        return false;
    }
}

