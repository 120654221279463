import React, { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import moment from "moment";

import {
    baseUrl,
    useGetUserQuery,
    useGetUserCoursesQuery,
    useDeleteUserMutation,
    useDeactivateUserMutation,
    useActivateUserMutation,
    useSendRemoveLearnerMutation,
} from "@api/apiV6";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { desnaked } from "@utilities/helpers";
import {
    Tab,
    Button as XButton,
    Confirm,
    Input,
    Icon,
} from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import EditUserModal from "./EditUserModal.react";
import EditLicenseModal from "./EditLicenseModal.react";
import { toast } from "react-toastify";
import EditDetailsModal from "./EditDetailsModal.react";
import { settings } from "@config/settings/app.settings";
import AdminEnrolModal from "./AdminEnrolModal.react";
import UserHierarchy from "./UserHierarchy.react";

export default function UserDetails(props) {
    const { userId } = props;
    const { data: baseData, isSuccess } = useGetUserQuery(userId);
    const { data: userCourses, isSuccess: userCoursesSuccess } =
        useGetUserCoursesQuery(userId);
    const [deleteUser] = useDeleteUserMutation();
    const [deactivateUser] = useDeactivateUserMutation();
    const [activateUser] = useActivateUserMutation();
    const [removeLearner] = useSendRemoveLearnerMutation();

    const auth = useSelector((state) => state.auth);
    const { t } = useTranslation("common");

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editDetailsOpen, setEditDetailsOpen] = useState(false);
    const [editLicenseOpen, setEditLicenseOpen] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [unenrollTarget, setUnenrollTarget] = useState(null);
    const [unenrollConfirm, setUnenrollConfirm] = useState(false);
    const [enrollmentOpen, setEnrollmentOpen] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordHelp, setPasswordHelp] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
            password
        )
            ? setValidPassword(true)
            : setValidPassword(false);
    }, [password]);

    const showData =
        isSuccess &&
        _.pick(baseData, [
            "id",
            "username",
            "first_name",
            "last_name",
            "email",
            "org",
            "team",
            "stage",
            "level",
            "active",
        ]);
    const showDates = isSuccess && _.pick(baseData, ["created_at"]);
    const showDetails = isSuccess && _.omit(baseData.details, "_id");
    const showLicense =
        isSuccess &&
        _.pick(baseData?.license, [
            "license_name",
            "license_type",
            "maxUsers",
            "maxDecks",
            "maxCourses",
            "maxArticles",
            "aiCredits",
            "subscription_plan",
            "start_date",
            "end_date",
        ]);
    function getDataTable(data, dates) {
        return _.map(_.toPairs(data), (row, idx) => {
            return (
                <Div fluid flex key={`user-det-${idx}`}>
                    <Div
                        padded
                        ash={idx % 2 === 0}
                        smoke={idx % 2 === 1}
                        wd="120px"
                        title={row[0].length > 10 ? desnaked(row[0]) : null}
                    >
                        {desnaked(row[0])}
                    </Div>
                    {dates == null && (
                        <Div
                            padded
                            ivory
                            wd="calc(100% - 120px)"
                            noOverflow
                            title={_.isString(row[1]) ? row[1] : null}
                        >
                            {row[1] === true
                                ? "Yes"
                                : row[1] === false
                                ? "No"
                                : row[1]}
                        </Div>
                    )}
                    {dates && (
                        <Div padded ivory wd="calc(100% - 120px)">
                            {moment(new Date(row[1]), "YYYY-MM-DD").format(
                                "DD MMM YYYY"
                            )}
                        </Div>
                    )}
                </Div>
            );
        });
    }
    function getCoursesTable(data) {
        return _.map(data, (row, idx) => {
            return (
                <Div
                    fluid
                    flex
                    key={`user-courses-${idx}`}
                    padded
                    ivory
                    noOverflow
                >
                    <Div wd="calc(100% - 16px)">
                        {row.name} ({row._id})
                    </Div>
                    <Div txtDanger wd="16px" clickable>
                        <Icon
                            name="trash"
                            onClick={() => {
                                setUnenrollTarget(row._id);
                                setUnenrollConfirm(true);
                            }}
                        />
                    </Div>
                </Div>
            );
        });
    }
    function showManager() {
        return (
            <Div fluid flex key={`user-manager`} spaceBetween>
                <Div padded smoke wd="100px">
                    Manager
                </Div>
                <Div wd="calc(100% - 110px)">{baseData.parent.username}</Div>
            </Div>
        );
    }
    async function resetPassword() {
        try {
            let formData = new FormData();
            formData.append("password", password);
            formData.append("password_confirmation", password);
            await axios({
                method: "post",
                url: `${baseUrl}api/v3/auth/change_password/${userId}`,
                data: formData,
                headers: { "access-token": auth.token },
            });
            toast("Password Updated");
        } catch (error) {
            toast(error.response.data.message);
        }
    }
    const panes = isSuccess
        ? [
              {
                  menuItem: { key: "profile", icon: "id badge", content: "" },
                  render: () => (
                      <Tab.Pane>
                          <Div bold gutter>
                              User Profile
                          </Div>
                          <Div flex column gapped gutter>
                              {getDataTable(showData)}
                              {getDataTable(showDates, "dates")}
                          </Div>
                          <XButton.Group fluid>
                              <XButton
                                  primary
                                  content="Edit"
                                  onClick={() => setEditModalOpen(true)}
                              />
                              {baseData.active ? (
                                  <XButton
                                      secondary
                                      content="Deactivate"
                                      onClick={() =>
                                          deactivateUser(baseData._id)
                                      }
                                  />
                              ) : (
                                  <XButton
                                      secondary
                                      content="Activate"
                                      onClick={() => activateUser(baseData._id)}
                                  />
                              )}
                              <XButton
                                  negative
                                  content="Delete"
                                  onClick={() => setDeleteConfirm(true)}
                              />
                          </XButton.Group>
                      </Tab.Pane>
                  ),
              },
              _.keys(settings.detailParams).length > 0 && {
                  menuItem: {
                      key: "details",
                      icon: "tags",
                      content: "",
                  },
                  render: () => (
                      <Tab.Pane>
                          <Div bold gutter>
                              User Details
                          </Div>
                          <Div flex column gapped gutter>
                              {showManager()}
                          </Div>
                          <Div flex column gapped gutter>
                              {getDataTable(showDetails)}
                          </Div>
                          <Button
                              primary
                              fluid
                              content="Edit Details"
                              onClick={() => setEditDetailsOpen(true)}
                          />
                      </Tab.Pane>
                  ),
              },
              {
                  menuItem: {
                      key: "license",
                      icon: "id card",
                      content: "",
                  },
                  render: () => (
                      <Tab.Pane>
                          <Div bold gutter>
                              User License
                          </Div>
                          <Div flex column gapped gutter>
                              {getDataTable(showLicense)}
                          </Div>
                          <Button
                              primary
                              fluid
                              content="Edit License"
                              onClick={() => setEditLicenseOpen(true)}
                          />
                      </Tab.Pane>
                  ),
              },
              {
                  menuItem: { key: "password", icon: "asterisk", content: "" },
                  render: () => (
                      <Tab.Pane>
                          <Div bold gutter>
                              Reset Password
                          </Div>
                          <Div gutter>
                              <Input
                                  fluid
                                  label="New Password"
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Input Password"
                                  onChange={(e) => setPassword(e.target.value)}
                                  icon={
                                      <Icon
                                          name={
                                              showPassword ? "eye slash" : "eye"
                                          }
                                          link
                                          onClick={() =>
                                              setShowPassword(!showPassword)
                                          }
                                      />
                                  }
                                  onFocus={() => setPasswordHelp(true)}
                                  onBlur={() => setPasswordHelp(false)}
                              />
                              {passwordHelp && (
                                  <Div charcoal padded small italics>
                                      {t("auth.registration.passwordHelp")}
                                  </Div>
                              )}
                              {!validPassword && password?.length > 0 && (
                                  <Div relaxed danger fluid>
                                      <i className="fa fa-times" /> &nbsp;
                                      Password is invalid
                                  </Div>
                              )}
                          </Div>
                          <Button
                              primary
                              fluid
                              content="Update Password"
                              onClick={resetPassword}
                          />
                      </Tab.Pane>
                  ),
              },
              {
                  menuItem: { key: "hierarchy", icon: "sitemap", content: "" },
                  render: () => (
                      <Tab.Pane>
                          <Div bold gutter>
                              Organization Hierarchy
                          </Div>
                          <Div gutter>
                              <UserHierarchy userId={userId} />
                          </Div>
                      </Tab.Pane>
                  ),
              },
              userCoursesSuccess && {
                  menuItem: {
                      key: "courses",
                      icon: "graduation cap",
                      content: "",
                  },
                  render: () => (
                      <Tab.Pane>
                          <Div bold gutter>
                              Enrolled Courses
                          </Div>
                          {getCoursesTable(userCourses)}
                          <br />
                          <Button
                              primary
                              fluid
                              content="Enroll into Course"
                              onClick={() => setEnrollmentOpen(true)}
                          />
                      </Tab.Pane>
                  ),
              },
          ].filter(Boolean)
        : [];
    // ========================= Render Function =================================
    return (
        <Div ash medpad>
            <Div bold uppercase gutter>
                {baseData?.username}
            </Div>
            {isSuccess && <Tab panes={panes} />}
            {isSuccess && (
                <Div>
                    <EditUserModal
                        key={`edit-user-${baseData._id}`}
                        isOpen={editModalOpen}
                        setOpen={setEditModalOpen}
                        user={baseData}
                    />
                    <EditDetailsModal
                        key={`edit-details-${baseData._id}`}
                        isOpen={editDetailsOpen}
                        setOpen={setEditDetailsOpen}
                        user={baseData}
                    />
                    <EditLicenseModal
                        key={`edit-license-${baseData._id}`}
                        isOpen={editLicenseOpen}
                        setOpen={setEditLicenseOpen}
                        user={baseData}
                    />
                    <AdminEnrolModal
                        key={`add-courses-${baseData._id}`}
                        isOpen={enrollmentOpen}
                        setOpen={setEnrollmentOpen}
                        user={baseData}
                    />
                    <Confirm
                        open={deleteConfirm}
                        onCancel={() => setDeleteConfirm(false)}
                        onConfirm={() => {
                            deleteUser(baseData._id);
                            setDeleteConfirm(false);
                        }}
                    />
                    <Confirm
                        open={unenrollConfirm}
                        onCancel={() => setUnenrollConfirm(false)}
                        onConfirm={() => {
                            removeLearner({
                                id: unenrollTarget,
                                user_id: baseData._id,
                            });
                            setUnenrollConfirm(false);
                        }}
                    />
                </Div>
            )}
        </Div>
    );
}
